import axios from 'axios';
import { message } from 'ant-design-vue';
// const router = require('@/router');
import VueRouter from 'vue-router';
// import { getToken } from '@/api/code.js';
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
});
const service = axios.create({
  timeout: 15000,
  headers: {
    'Access-Control-Expose-Headers': 'authorization',
    'Content-Type': 'application/json',
    // Authorization: getToken(),

  },
});

// const isRedirect = [ 'login', 'productList' ].map(item => location.href.includes(item));
const isRedirect = [ 'login', 'productList' ].map(item => location.href.indexOf(item));

// 添加请求拦截器
if (!sessionStorage.getItem('username') && !isRedirect) {
  message.error('用户未登录，请先登录！');
  router.replace({ name: 'login' });
} else {
  service.interceptors.request.use(
    config => {
      // console.log(router);
      const TOKEN = localStorage.getItem('token');
      // console.log(TOKEN, 'TOKEN');
      if (TOKEN) {
        config.headers.Authorization = TOKEN; // 让每个请求携带自定义token 请根据实际情况自行修改
      }
      return config;
    }, error => {
      console.log(error);
      return Promise.reject(error);
    }
  );
}


export default service;
