<template>
  <div>
    <!-- <div
      class="layoutHeader"
      v-if="!$route.meta.isHide"
    >
      <div class="container">
        <h2 class="logo">
          <a
            href="javascript:;"
            @click="clickLogo"
          />
        </h2>
        <div class="hotline">
          <span>业务热线</span><strong>132 1615 7642</strong><br>
          <span>保险咨询</span><strong>010-64789983</strong>
        </div>
      </div>
    </div> -->

    <div
      class="layoutMain"
    >
      <div
        class="activity"
        v-if="discount.enableFlag === '1' && isNeedSidebarLayout"
      >
        <img
          :src="discount.bannerPath"
          alt=""
        >
      </div>
      <div
        class="container sidebarLayout"
        v-if="isNeedSidebarLayout && $route.meta.isShow!=1"
      >
        <div class="slidebar">
          <div class="user">
            <img
              src="@/assets/image/pic.png"
              alt=""
            >
            <div
              class="phone"
            >
              <p class="greet">
                欢迎你
              </p>
              <p class="companyName">
                {{ data.companyName }}
              </p>
            </div>
            <div style="height: 40px;" />
            <!-- <div
              v-if="certificationFlag === '1'"
              class="verify2"
              @click="getEntCert"
            >
              <i />
              <div>认证通过</div>
            </div>
            <div
              v-else
              class="verify"
              @click="getEntCertForm"
            >
              <i />
              <div v-if="certificationFlag === '0'">
                未认证
              </div>
              <div v-if="certificationFlag === '3'">
                认证失败
              </div>
              <div v-if="certificationFlag === '2'">
                等待审核
              </div>
            </div> -->
          </div>
          <div class="nav">
            <ul>
              <li
                :class="[item.isActive?'active':'']"
                v-for="item in navList"
                :key="item.name"
              >
                <router-link :to="item.href">
                  <img
                    :src="item.icon"
                    v-if="item.isActive"
                  >
                  <img
                    :src="item.icon2"
                    v-else
                  >
                  {{ item.name }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="operate">
            <a-button
              class="btn"
              @click="exit"
            >
              退出登录
            </a-button>
          </div>
        </div>
        <div class="main">
          <router-view />
        </div>
      </div>
      <router-view v-else />
    </div>
    <div
      class="layoutFooter"
      v-if="$route.meta.isOpen==3"
    >
      <!-- Copyright&nbsp;©&nbsp;2006-2015&nbsp;&nbsp;Yinsheng.&nbsp;&nbsp;All Rights Reserved -->
    </div>
  </div>
</template>

<script>
import { Button } from 'ant-design-vue';
import { telephone } from '@/utils/format.js';
import { info } from '@/api/user/user.js';
import { logout } from '@/api/login/register.js';
import { entInfo } from '@/api/entCertification/entCertification.js';
const notNeedSidebarLayoutList = [ '/login', '/demo', '/register', '/forgotPassword', '/entCertForm', '/entCertification', '/bankInsure' ];

export default {
  name: 'BasicLayout',
  components: {
    aButton: Button,
  },
  inject: [ 'reload' ],
  data() {
    return {
      discount: {
        enableFlag: '0',
        bannerPath: '',
      },
      data: {},
      certificationFlag: '0',
      telephone,
      phone: '',
      isNeedSidebarLayout: true,
      navList: [
        {
          name: '申请保函',
          href: '/insure/project/view?count=1',
          isActive: false,
          icon: require('../assets/image/icon-sqbh-hover.png'),
          icon2: require('../assets/image/icon-sqbh.png'),
        },
        {
          name: '订单中心',
          href: '/orderCenter',
          isActive: false,
          icon: require('../assets/image/icon-ddzx-hover.png'),
          icon2: require('../assets/image/icon-ddzx.png'),
        }, {
          name: '企业信息',
          href: '/user',
          isActive: false,
          icon: require('../assets/image/icon-zhgl-hover.png'),
          icon2: require('../assets/image/icon-zhgl.png'),
        },
      ],
    };
  },
  computed: {
    urlPrefix() {
      return this.getUrlPrefix(this.$route.path);
    },
  },
  watch: {
    $route() {
      if (this.phone !== JSON.parse(sessionStorage.getItem('telephone'))) {
        this.info();
      }
      this.getIsNeedSidebarLayout();
      this.changeNavItemActive();
    },
  },
  created() {
    if (this.$route.path !== '/insure' && this.$route.path !== '/login') {
      this.info();
    }
    this.getIsNeedSidebarLayout();
    this.changeNavItemActive();

  },
  methods: {
    clickLogo() {
      if (this.$route.path === '/login' || this.$route.path === '/insure/project/view?count=1') {
        return false;
      } else if (this.$route.path === '/forgotPassword' || this.$route.path === '/register') {
        this.$router.push({ name: 'login' });
      } else {
        this.$router.push({ name: 'Project' });
      }
    },
    info() {
      entInfo().then(res => {
        if (res.data.status === 'SUCCESS') {
          this.data = res.data.data;
          this.$store.state.info = res.data.data;
          if (res.data.data.discount) {
            this.discount = res.data.data.discount;
            this.$store.state.discount = res.data.data.discount;
          }
          this.certificationFlag = res.data.data.certificationFlag;
          this.phone = res.data.data.phoneNum;
        }
      });
      info().then(res => {
        if (res.data.status === 'SUCCESS') {
          // if (res.data.data.discount) {
          //   this.discount = res.data.data.discount;
          //   this.$store.state.discount = res.data.data.discount;
          // }
          // this.certificationFlag = res.data.data.certificationFlag;
          this.phone = res.data.data.phoneNum;
          sessionStorage.setItem('username', JSON.stringify(res.data.data.username));
        } else {
          if (window.location.pathname !== process.env.VUE_APP_FIRST_URL + '/forgotPassword' && window.location.pathname !== process.env.VUE_APP_FIRST_URL + '/register' && window.location.pathname !== process.env.VUE_APP_FIRST_URL + '/login' && window.location.pathname !== process.env.VUE_APP_FIRST_URL + '/insure' && window.location.pathname !== process.env.VUE_APP_FIRST_URL + '/insure/basicDoor') {
            // if (res.data.errorMsg === '用户未登录') {
            this.$router.push({ name: 'login' });
            // }
          }
        }
      });
    },
    getEntCert() {
      this.$router.push({ name: 'entCertification' });
    },
    getEntCertForm() {
      this.$router.push({ name: 'entCertForm' });
    },
    exit() {
      logout().then(res => {
        if (res.data.status === 'SUCCESS') {
          sessionStorage.clear();
          localStorage.clear();
          this.reload();
          // this.$router.push({ name: 'login' });
          window.location.href = '/login';
          localStorage.removeItem('token');
        }
      });
    },
    getUrlPrefix(url) {
      return `/${url.split('/')[1]}`;
    },
    getIsNeedSidebarLayout() {
      this.isNeedSidebarLayout = !notNeedSidebarLayoutList.includes(this.urlPrefix);
    },
    changeNavItemActive() {
      this.navList.forEach((item, index) => {
        this.navList[index].isActive = this.getUrlPrefix(item.href) === this.urlPrefix;
      });
    },
  },
};
</script>

<style lang="less" scoped>
@deep: ~'>>>';
  .container{
    width: 1180px;
    margin:0 auto;
  }
  .sidebarLayout{
    margin: 30px auto;
    display: flex;
    background-color: #fff;
    .slidebar{
      width:180px;
      border-right:1px solid #EBF2F7;
      .user{
        position: relative;
        img {
          position: absolute;
          top: -31px;
        }
        .phone{
          width: 111px;
          margin: 0 auto;
          margin-top: 15px;
          font-size: 15px;
          font-weight: 500;
          color: #333333;
          .greet{
            margin-top: 32px;
            font-size: 16px;
            color: #999999;
          }
          .companyName {
            width: 111px;
            height: 22px;
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
          }
        }
        .verify{
          margin: 0 auto;
          cursor: pointer;
          margin-top: 23px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          height: 30px;
          background: #F0F3F7;
          border-radius: 15px;
          color:#AAAAAA;
          i {
            width: 14px;
            height: 14px;
            margin-right: 8px;
            background:url('../assets/image/icon-v1.png') no-repeat center center;
          }
          div {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #AAAAAA;
          }
        }
        .verify2 {
          margin: 0 auto;
          cursor: pointer;
          width: 120px;
          height: 30px;
          background: rgba(255, 220, 146, .3);
          border-radius: 15px;
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            width: 14px;
            height: 14px;
            background:url('../assets/image/icon-v2.png') no-repeat center center;
            margin-right: 8px;
          }
          div {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFAA00;
          }
        }
      }
      .nav{
        margin-top: 40px;
        ul{
          padding: 0;
        }
        li{
          list-style: none;
          border-left: 4px solid #fff;
          img {
            width: 20px;
            height: 20px;
            margin-right: 9px;
          }
          &.active{
            border-left:4px solid #007CE2;
            background-color:#F7FAFF;
            a{
              color:#007CE2;
              font-weight: bold;
            }
          }
          a{
            height:50px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: #666666;
            font-weight: 400;
          }
        }
      }
      .operate{
        text-align: center;
        padding: 20px 0;
        width: 140px;
        margin:20px auto 0;
        border-top:1px solid #ecf3f8;
        .btn{
          width: 120px;
          height: 30px;
          background: #FFFFFF;
          border: 1px solid #D2E1EC;
          border-radius: 15px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          color: #6A95B5;
        }
      }
    }
    .main{
      flex:1;
      padding:30px 40px;
    }
  }
  .layoutHeader{
    background-color: #fff;
    .container{
      height: 90px;
      overflow: hidden;
    }
    .logo{
      margin-top: 18px;
      float: left;
      a{
        width: 140px;
        height: 55px;
        display: inline-block;
        background:url('../assets/image/logo.png') no-repeat center center;
        background-size:auto 55px;
        overflow: hidden;
        text-indent: -9999px;
      }
    }
    .slogan{
      margin: 35px 0 0 20px;
      float: left;
      color:#999;
    }
    .hotline{
      margin-top:10px;
      float:right;
      color:#999;
      span{
        vertical-align:middle;
      }
      strong{
        vertical-align:middle;
        margin-left:10px;
        font-size:22px;
        color:#007CE2;
      }
    }
  }
  .activity {
    text-align: center;
    margin-top: 30px;
    img {
      width: 1180px;
      height: 100px;
    }
  }

  .layoutMain{
    overflow: hidden;
    background: url(./../assets/image/main-bg-top.png) top center no-repeat, url(./../assets/image/main-bg-bottom.png) bottom center no-repeat;
    background-size:contain;
    background-color: #F5F6FA;
    min-height: 830px;
  }
  .layoutFooter{
    padding:20px 0;
    background-color: #10284c;
    vertical-align: middle;
    text-align: center;
    font-size: 14px;
    color: #F9FBFC;
  }
</style>
