
import '@babel/polyfill';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { message, Modal, Steps } from 'ant-design-vue';
import Antd from 'ant-design-vue/es';
import 'ant-design-vue/dist/antd.css';
import 'normalize.css/normalize.css';
import './global.less'; // global style
import './assets/css/base.less';
// import '@babel/polyfill';
Vue.prototype.$message = message;
Vue.config.productionTip = false;
Vue.use(Modal);
Vue.use(Steps);
Vue.use(Antd);
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');


// window.onload = () => {
//   const secObj = document.getElementsByName('SDCount')[0];
// eslint-disable-next-line no-undef
//   ChangeDecType(secObj.selectedIndex);
// };
