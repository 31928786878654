import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    projectView: '',
    info: {},
    discount: {},
  },
  mutations: {
    getInfo(state, data) {
      state.info = data;
    },
  },
  actions: {
  },
  modules: {
  },
});
