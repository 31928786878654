import request from '@/utils/request';

export function submitApproveInfo(data) {
  return request({
    url: '/api/accCertification/submitApproveInfo',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data,
  });
}

export function entInfo() {
  return request({
    url: '/api/accCertification/info',
    method: 'get',
    headers: {
      'Cache-control': 'no-cache',
      Pragma: 'no-cache',
    },
  });
}

export default { submitApproveInfo, entInfo };
