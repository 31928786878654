import dayjs from 'dayjs';

const dateFormat = value => {
  return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
};

const dateFormatDay = value => {
  return dayjs(value).format('YYYY-MM-DD');
};

const amountFormat = value => {
  return value.toLocaleString('en-US');
};

const telephone = value => {
  const reg = /^(\d{3})\d{4}(\d{4})$/;
  return value.replace(reg, '$1****$2');
};

// 节流
const throttle = (fn, time) => {
  let timer = null;
  time = time || 1000;
  return function(...args) {
    if (timer) {
      return;
    }
    const _this = this;
    timer = setTimeout(() => {
      timer = null;
    }, time);
    fn.apply(_this, args);
  };
};
export {
  dateFormat,
  dateFormatDay,
  amountFormat,
  telephone,
  throttle,
};
