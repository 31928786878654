<template>
  <div id="app">
    <div>
      <Header v-if="$route.meta.isHide == 1" />
      <router-view v-if="isRouterAlive" />
      <Footer v-if="$route.meta.isHide == 1 && $route.meta.isOpen != 3" />
    </div>
  </div>
</template>
<script>
import Header from './components/header.vue';
import Footer from './components/footer.vue';
export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  mounted() {
  },
  methods: {
    reload() {
      this.logins = false;
      this.isRouterAlive = false;
      this.$nextTick(function() {
        this.logins = true;
        this.isRouterAlive = true;
      });
    },
  },
};
</script>
<style lang="less" scoped>
// #app{
//   min-width: 1500px;
// }
/deep/ .ant-pagination-prev .ant-pagination-item-link, /deep/ .ant-pagination-next .ant-pagination-item-link {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
