import request from '@/utils/request';

export function info(params) {
  return request({
    url: '/api/accCustomer/info',
    method: 'get',
    headers: {
      'Cache-control': 'no-cache',
      Pragma: 'no-cache',
    },
    params,
  });
}

export function modifyPhoneStepOne(params) {
  return request({
    url: '/api/accCustomer/modifyPhoneStepOne',
    method: 'get',
    params,
  });
}
export function modifyPhoneStepTwo(params) {
  return request({
    url: '/api/accCustomer/modifyPhoneStepTwo',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params,
  });
}
export function modifyPassword(params) {
  return request({
    url: '/api/accCustomer/modifyPassword',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params,
  });
}
export function location(params) {
  return request({
    url: '/api/accCustomer/location',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params,
  });
}
export function setPassword(params) {
  return request({
    url: '/api/accCustomer/setPassword',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params,
  });
}

export function updateAcc(data) {
  return request({
    url: '/api/accCustomer/updateAcc',
    method: 'post',
    data,
  });
}

export default { updateAcc, info, modifyPhoneStepOne, modifyPhoneStepTwo, modifyPassword, location, setPassword };
