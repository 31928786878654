import request from '@/utils/request';

export function register(params) {
  return request({
    url: '/api/login/register',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params,
  });
}
export function login(params) {
  return request({
    url: '/api/login/login',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params,
  });
}
export function checkRetrieveCode(params) {
  return request({
    url: '/api/login/checkRetrieveCode',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params,
  });
}

export function retrieve(params) {
  return request({
    url: '/api/login/retrieve',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params,
  });
}

export function cetRandom(params) {
  return request({
    url: '/api/crypto/cetRandom',
    method: 'get',
    params,
  });
}

export function CAlogin(params) {
  return request({
    url: '/api/crypto/certVerify/login',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params,
  });
}

export function logout() {
  return request({
    url: '/api/login/logout',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

export function loginCode(params) {
  return request({
    url: '/api/login/loginCode',
    method: 'post',
    params,
  });
}

export function loginCA(data) {
  return request({
    url: '/api/login/loginCA',
    method: 'post',
    data,
  });
}

export default { loginCA, register, login, checkRetrieveCode, retrieve, cetRandom, CAlogin, logout, loginCode };
